import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateRateCategoryModal from "./createRateCategory";
import { deleteRateCategory, getRateCategoryByResortId, getRateClassByResortIdRateClasstId } from "../../../../../../../apis/property";
import EditRateCategory from "./editRateCategory";
const RateCategory = () => {
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [rateCategory, setRateCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedRateId, setSelectedRateId] = useState(null);
  const [rateClassNames, setrateClassNames] = useState({});

  const fetchRoomClass = async (rateClassId) => {
    try {
      const response = await getRateClassByResortIdRateClasstId(rateClassId);
      return response.data.name;
    } catch (error) {
      console.log('Error fetching rate class');
      return '';
    }
  };

  const fetchRateCategory = async () => {
    try {
      const response = await getRateCategoryByResortId(id);
      const roomCategories = response.data.categories;
      // Fetch room class names
      const rateClassNamesPromises = roomCategories.map(async (room) => {
        const rateClassName = await fetchRoomClass(room.rate_class_id);
        return { id: room.id, rateClassName };
      });

      const rateClassNamesResults = await Promise.all(rateClassNamesPromises);
      const rateClassNamesMap = rateClassNamesResults.reduce((acc, { id, rateClassName }) => {
        acc[id] = rateClassName;
        return acc;
      }, {});

      setrateClassNames(rateClassNamesMap);
      setRateCategory(roomCategories);
      setLoading(false);
    } catch (error) {
      console.log('Error fetching room category');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRateCategory();
  }, []);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = async () => {
    setIsModalOpen(false);
    await fetchRateCategory();
  };


  const openEditModal = (rateId) => {
    setSelectedRateId(rateId);
    setIsEditModalOpen(true);
  };

  const closeEditModal = async () => {
    setIsEditModalOpen(false);
    await fetchRateCategory();
    setSelectedRateId(null);
  };

  const handleDelete = async (resort_category_id) => {
    try {
      await deleteRateCategory(resort_category_id);
      toast.success("Rate category deleted successfully");
      await fetchRateCategory();
    } catch (error) {
      toast.error("Failed to delete rate category");
    }
  };
  return (
    <div>
      <div>
        <div className="mt-5 flex justify-between">
          <h1 className="text-2xl font-bold mt-3">
            Room Category Configuration
          </h1>
          <button
            onClick={openModal}
            type="button"
            className="text-white mt-3 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 ..."
          >
            Add Room Category
          </button>
        </div>
        <CreateRateCategoryModal
          isOpen={isModalOpen}
          onClose={closeModal}
        // onResortCreated={fetchResorts}
        />
        <EditRateCategory
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          selectedRateId={selectedRateId}
        />
        <div className="mt-10">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 shadow-lg">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Room Category Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Room Category Code
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Room Category Description
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Room class
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {rateCategory?.map((rate) => (
                  <tr key={rate.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {rate.name}
                    </th>
                    <td className="px-6 py-4">{rate.code}</td>
                    <td className="px-6 py-4">{rate.description}</td>
                    <td className="px-6 py-4">{rateClassNames[rate.id]}</td>
                    <td className="px-6 py-4 flex">
                      <div
                        className="font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline" onClick={() => openEditModal(rate.id)}
                      >
                        Edit
                      </div>
                      <div
                        className="font-medium cursor-pointer text-red-600 dark:text-red-500 hover:underline ms-3" onClick={() => handleDelete(rate.id)}
                      >
                        Remove
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default RateCategory;
