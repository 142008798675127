import React, { useState, useEffect } from "react";
import axios from "axios";
import Tabs from "../../../../components/Tabs";
import Overview from "./TabSets/Overview/overview";
import Availability from "./TabSets/Availability/availability";
import Reservations from "./TabSets/Reservations/reservations";
import Configurations from "./TabSets/Configurations/configurations";
import { useParams } from 'react-router-dom';

const ViewProperty = () => {
  const { id } = useParams();
  const [resort, setResort] = useState(null);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchResort = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const response = await axios.get(`${baseUrl}/resort/${id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setResort(response.data.data);
      } catch (error) {
        console.error("Error fetching resort:", error);
      }
    };

    fetchResort();
  }, [baseUrl, id]);


  const tabs = [
    { name: "Overview", component: Overview },
    { name: "Availability", component: Availability },
    { name: "Reservations", component: Reservations },
    { name: "Configurations", component: Configurations },
  ];

  return (
    <div className="container mx-auto mt-5">
      {resort ? (
        <div>
          <h1 className="text-2xl font-bold mt-3">{resort.resort_name}</h1>
          {/* Display other resort details here */}
        </div>
      ) : (
        <p>Loading...</p>
      )}
        <Tabs tabs={tabs} />
    </div>
  );
};

export default ViewProperty;
