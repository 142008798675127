import React, { useState, useEffect } from "react";
import CreateRoomClassModal from "./createRoomClass";
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deleteRoomClass, getRoomClassByResortId } from "../../../../../../../apis/property";
import EditRoomClass from "./editRoomClass";

const RoomClass = () => {
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [roomClass, setRoomClass] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedRoomId, setSelectedRoomId] = useState(null);


  const fetchRoomClass = async () => {
    try {
      const response = await getRoomClassByResortId(id);
      setRoomClass(response.data);
      setLoading(false);
    } catch (error) {
      console.log('Error fetching room class');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRoomClass();
  }, []);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = async () => {
    setIsModalOpen(false);
    await fetchRoomClass();
    // window.location.reload();
  };


  const openEditModal = (roomId) => {
    setSelectedRoomId(roomId);
    setIsEditModalOpen(true);
  };

  const closeEditModal = async () => {
    setIsEditModalOpen(false);
    await fetchRoomClass();
    setSelectedRoomId(null);
  };
  
  const handleDelete = async (resort_class_id) => {
    try {
      await deleteRoomClass(resort_class_id);
      toast.success("Room Class deleted successfully");
      await fetchRoomClass();
      // Optionally, fetch updated room classes or refresh the page
    } catch (error) {
      toast.error("Failed to delete Room Class");
    }
  };

  return (
    <div>
      <div>
        <div className="mt-5 flex justify-between">
          <h1 className="text-2xl font-bold mt-3">Room Class Configuration</h1>
          <button
            onClick={openModal}
            type="button"
            className="text-white mt-3 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 ..."
          >
            Add Room Class
          </button>
        </div>
        <CreateRoomClassModal
          isOpen={isModalOpen}
          onClose={closeModal}
        />
         <EditRoomClass
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          selectedRoomId={selectedRoomId}
        />
        <div className="mt-10">
        {loading ? (
            <p>Loading...</p>
          ) : (
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 shadow-lg">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Room class Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Room class Code
                </th>
                <th scope="col" className="px-6 py-3">
                  Room class Description
                </th>
                <th scope="col" className="px-6 py-3">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
            {roomClass?.map((room) => (
                  <tr key={room.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {room.name}
                    </th>
                    <td className="px-6 py-4">{room.code}</td>
                    <td className="px-6 py-4">{room.description}</td>
                <td className="px-6 py-4 flex">
                  <div
                    className="font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline"  onClick={() => openEditModal(room.id)}
                  >
                    Edit
                  </div>
                  <div
                    className="font-medium cursor-pointer text-red-600 dark:text-red-500 hover:underline ms-3" onClick={() => handleDelete(room.id)}
                  >
                    Remove
                  </div>
                </td>
              </tr>
                ))}
            </tbody>
          </table>
           )}
        </div>
      </div>
    </div>
  );
};

export default RoomClass;
