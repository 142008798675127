import React, { useState, useEffect } from "react";
import CreateAmenityCategoryModal from "./createAmenityCategory";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  deleteAmenityCategory,
  getAllAmenityCategories,
} from "../../../../../../../apis/property";
import EditAmenityCategoryModal from "./editAmenityCategory";

const AmenityCategory = () => {
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [amentityCategory, setAmentityCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedAmenityCategoryId, setSelectedAmenityCategoryId] =
    useState(null);

  const fetchAmenityCategory = async () => {
    try {
      const response = await getAllAmenityCategories();
      setAmentityCategory(response.data);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching Amenity Category");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAmenityCategory();
  }, []);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = async () => {
    setIsModalOpen(false);
    await fetchAmenityCategory();
    // window.location.reload();
  };

  const openEditModal = (amenity_category_id) => {
    setSelectedAmenityCategoryId(amenity_category_id);
    setIsEditModalOpen(true);
  };

  const closeEditModal = async () => {
    setIsEditModalOpen(false);
    await fetchAmenityCategory();
    setSelectedAmenityCategoryId(null);
  };

  const handleDelete = async (amenity_category_id) => {
    try {
      await deleteAmenityCategory(amenity_category_id);
      toast.success("Amenity Category deleted successfully");
      await fetchAmenityCategory();
      // Optionally, fetch updated Amenity Categoryes or refresh the page
    } catch (error) {
      toast.error("Failed to delete Amenity Category");
    }
  };

  return (
    <div>
      <div>
        <div className="mt-5 flex justify-between">
          <h1 className="text-2xl font-bold mt-3">
            Amenity Category Configuration
          </h1>
          <button
            onClick={openModal}
            type="button"
            className="text-white mt-3 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 ..."
          >
            Add Amenity Category
          </button>
        </div>
        <CreateAmenityCategoryModal isOpen={isModalOpen} onClose={closeModal} />
        <EditAmenityCategoryModal
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          selectedAmenityCategoryId={selectedAmenityCategoryId}
        />
        <div className="mt-10">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 shadow-lg">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Amenity Category Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Amenity Category Description
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {amentityCategory?.map((amenityCategory) => (
                  <tr
                    key={amenityCategory.id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {amenityCategory.name}
                    </th>
                    <td className="px-6 py-4">{amenityCategory.description}</td>
                    <td className="px-6 py-4 flex">
                      <div
                        className="font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline"
                        onClick={() => openEditModal(amenityCategory.id)}
                      >
                        Edit
                      </div>
                      <div
                        className="font-medium cursor-pointer text-red-600 dark:text-red-500 hover:underline ms-3"
                        onClick={() => handleDelete(amenityCategory.id)}
                      >
                        Remove
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default AmenityCategory;
