import React from "react";

const ImageModal = ({ isOpen, onClose, imageUrl }) => {
  if (!isOpen) return null;

  const handleBackdropClick = (event) => {
    // Close the modal only if the backdrop is clicked
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      onClick={handleBackdropClick} // Add click handler to the backdrop
    >
      <div className="relative">
        <img src={imageUrl} alt="Room" className="max-w-full max-h-full" />
        <button onClick={onClose} className="absolute top-2 right-2 bg-white rounded-full p-2 shadow">
          X
        </button>
      </div>
    </div>
  );
};

export default ImageModal;
