import React from "react";
import RoomInventoryCalendar from "../../../../../../components/RoomInventoryCalendar";

const Availability = () => {
  return (
    <div>
      <RoomInventoryCalendar />
    </div>
  );
};

export default Availability;
