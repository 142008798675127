import React, { useState } from "react";

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].name);

  return (
    <div>
      <div className="flex border-b border-gray-200">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            className={`px-20 py-2 -mb-px font-semibold border-b-2 transition-colors duration-300 ease-in-out ${
              activeTab === tab.name
                ? "border-indigo-500 text-indigo-600"
                : "border-transparent text-gray-500 hover:text-gray-700"
            }`}
            onClick={() => setActiveTab(tab.name)}
          >
            {tab.name}
          </button>
        ))}
      </div>
      <div className="p-4">
        {tabs.map((tab) => (
          <div
            key={tab.name}
            className={`transition-opacity duration-300 ease-in-out ${
              activeTab === tab.name ? "opacity-100" : "opacity-0 absolute"
            }`}
          >
            <div className="border-solid border-2 border-slate-300 p-7 mt-5 rounded-lg">{activeTab === tab.name && <tab.component />}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
