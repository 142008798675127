import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateResortModal = ({ isOpen, onClose, fetchResorts }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [formData, setFormData] = useState({
    resort_name: "",
    resort_code: "",
    resort_description: "",
    resort_phone: "",
    resort_email: "",
    resort_address_one: "",
    resort_address_two: "",
    resort_logo: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await fetch(`${baseUrl}/resort`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Property created successfully");
        onClose(); // Close the modal
        fetchResorts();
      } else {
        toast.error("Failed to create Resort");
        console.error("Failed to create Resort");
      }
    } catch (error) {
      toast.error("Error occurred while creating Resort");
      console.error("Error occurred while creating Resort:", error);
    }
  };

  return (
    <div className={`fixed z-10 inset-0 overflow-y-auto ${isOpen ? "block" : "hidden"}`}>
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        &#8203;
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <form onSubmit={handleSubmit}>
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="">
                <div className="mt-3">
                  <h3 className="text-lg leading-6 font-bold text-gray-900" id="modal-headline">
                    Create New Property
                  </h3>
                  <div className="mt-10">
                    <div className="mb-4">
                      <label htmlFor="resort_name" className="block text-gray-700 text-sm font-bold mb-2">
                        Resort Name
                      </label>
                      <input
                        type="text"
                        id="resort_name"
                        name="resort_name"
                        value={formData.resort_name}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Enter Resort name"
                        required
                      />
                    </div>

                    {/* Add other input fields for collecting resort data */}

                    {/* For Resort Code */}
                    <div className="mb-4">
                      <label htmlFor="resort_code" className="block text-gray-700 text-sm font-bold mb-2">
                        Resort Code
                      </label>
                      <input
                        type="text"
                        id="resort_code"
                        name="resort_code"
                        value={formData.resort_code}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Enter Resort code"
                        required
                      />
                    </div>

                    {/* For Resort Description */}
                    <div className="mb-4">
                      <label htmlFor="resort_description" className="block text-gray-700 text-sm font-bold mb-2">
                        Resort Description
                      </label>
                      <textarea
                        id="resort_description"
                        name="resort_description"
                        value={formData.resort_description}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Enter Resort description"
                        required
                      />
                    </div>

                    {/* For Resort Phone */}
                    <div className="mb-4">
                      <label htmlFor="resort_phone" className="block text-gray-700 text-sm font-bold mb-2">
                        Phone
                      </label>
                      <input
                        type="number"
                        id="resort_phone"
                        name="resort_phone"
                        value={formData.resort_phone}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Enter phone number"
                        required
                      />
                    </div>

                    {/* For Resort Email */}
                    <div className="mb-4">
                      <label htmlFor="resort_email" className="block text-gray-700 text-sm font-bold mb-2">
                        Resort Email
                      </label>
                      <input
                        type="email"
                        id="resort_email"
                        name="resort_email"
                        value={formData.resort_email}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Enter Resort email"
                        required
                      />
                    </div>

                    {/* For Resort Address One */}
                    <div className="mb-4">
                      <label htmlFor="resort_address_one" className="block text-gray-700 text-sm font-bold mb-2">
                        Resort Address Line 1
                      </label>
                      <input
                        type="text"
                        id="resort_address_one"
                        name="resort_address_one"
                        value={formData.resort_address_one}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Enter Resort address line 1"
                        required
                      />
                    </div>

                    {/* For Resort Address Two */}
                    <div className="mb-4">
                      <label htmlFor="resort_address_two" className="block text-gray-700 text-sm font-bold mb-2">
                        Resort Address Line 2
                      </label>
                      <input
                        type="text"
                        id="resort_address_two"
                        name="resort_address_two"
                        value={formData.resort_address_two}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Enter resort address line 2"
                        required
                      />
                    </div>

                    {/* For Resort Logo */}
                    <div className="mb-4">
                      <label htmlFor="resort_logo" className="block text-gray-700 text-sm font-bold mb-2">
                        Resort Logo URL
                      </label>
                      <input
                        type="text"
                        id="resort_logo"
                        name="resort_logo"
                        value={formData.resort_logo}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Enter Resort logo URL"
                        required
                      />
                    </div>

                    {/* Add the remaining input fields similarly */}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Create Property
              </button>
              <button
                onClick={onClose}
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateResortModal;
