import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;


export const getRole = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios.get(`${baseUrl}/role`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      return response.data;
    } catch (error) {
      console.error('Error fetching role:', error);
      throw error;
    }
  };
  