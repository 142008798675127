import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CreateRoomTypeModal from "./createRoomType";
import { toast } from "react-toastify";
import {
  deleteRoomTypeImage,
  deleteRoomType,
  getRoomClassByResortIdResorCategoryId,
  getRoomTypeByResortId,
} from "../../../../../../../apis/property";
import EditRoomType from "./EditRoomType";
import ImageModal from "./imageModal";

const RoomType = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [roomType, setRoomType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedTypeId, setSelectedTypeId] = useState(null);
  const [roomCategoryNames, setRoomCategoryNames] = useState({});
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");

  const handleImageClick = (url) => {
    setSelectedImageUrl(url);
    setIsImageModalOpen(true);
  };

  const fetchRoomCategory = async (RoomCategoryId) => {
    try {
      const response = await getRoomClassByResortIdResorCategoryId(
        id,
        RoomCategoryId
      );
      return response.data.name;
    } catch (error) {
      console.log("Error fetching room class");
      return "";
    }
  };

  const fetchRoomType = async () => {
    try {
      const response = await getRoomTypeByResortId(id);
      const roomType = response.data;
      // Fetch room class names
      const roomCategoryNamesPromises = roomType.map(async (room) => {
        const roomCategoryName = await fetchRoomCategory(room.room_category_id);

        return { id: room.id, roomCategoryName };
      });

      const roomCategoryNamesResults = await Promise.all(
        roomCategoryNamesPromises
      );
      const roomCategoryNamesMap = roomCategoryNamesResults.reduce(
        (acc, { id, roomCategoryName }) => {
          acc[id] = roomCategoryName;
          return acc;
        },
        {}
      );

      setRoomCategoryNames(roomCategoryNamesMap);
      setRoomType(roomType);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching room category");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRoomType();
  }, []);

  const handleDeleteImage = async (roomId, imageId) => {
    try {
      await deleteRoomTypeImage(roomId, imageId);
      toast.success("Image deleted successfully");
      fetchRoomType(); // Refresh the data after deletion
    } catch (error) {
      toast.error("Failed to delete image");
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = async () => {
    setIsModalOpen(false);
    await fetchRoomType();
  };
  const openEditModal = (roomId) => {
    setSelectedTypeId(roomId);
    setIsEditModalOpen(true);
  };

  const closeEditModal = async () => {
    setIsEditModalOpen(false);
    await fetchRoomType();
    setSelectedTypeId(null);
  };

  const handleDelete = async (resort_type_id) => {
    try {
      await deleteRoomType(resort_type_id);
      toast.success("Room type deleted successfully");
      await fetchRoomType();
    } catch (error) {
      toast.error("Failed to delete room type");
    }
  };
  return (
    <div>
      <div className="mt-5 flex justify-between">
        <h1 className="text-2xl font-bold mt-3">Room Type Configuration</h1>
        <button
          onClick={openModal}
          type="button"
          className="text-white mt-3 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 ..."
        >
          Add Room Type
        </button>
      </div>
      <CreateRoomTypeModal
        isOpen={isModalOpen}
        onClose={closeModal}
        // onResortCreated={fetchResorts}
      />
      <EditRoomType
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        selectedTypeId={selectedTypeId}
      />
      <div className="mt-10">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 shadow-lg">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Room Type Code
                </th>
                <th scope="col" className="px-6 py-3">
                  Room Type Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Room Type Description
                </th>
                <th scope="col" className="px-6 py-3">
                  Room Category
                </th>
                <th className="px-6 py-3">Room Image</th>
                <th scope="col" className="px-6 py-3">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {roomType?.map((room) => (
                <tr
                  key={room.id}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {room.code}
                  </th>
                  <td className="px-6 py-4">{room.name}</td>
                  <td className="px-6 py-4">{room.description}</td>
                  <td className="px-6 py-4">{roomCategoryNames[room.id]}</td>
                  {/* <td className="px-6 py-4">
                    {room.images?.map((image) => (
                      <div key={image.id} className="relative inline-block m-2">
                        <img
                          src={image.url}
                          alt={image.name}
                          className="h-20 w-20 object-cover rounded-lg"
                        />
                        <button
                          onClick={() => handleDeleteImage(room.id, image.id)}
                          className="absolute -top-3 -right-3 bg-red-500 text-white rounded-full 
                 flex items-center justify-center font-bold text-sm 
                 transition-transform transform duration-300 ease-in-out 
                 hover:scale-125 hover:bg-red-600 shadow-lg"
                          style={{ width: "30px", height: "30px" }}
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </td> */}
                  <td className="px-6 py-4">
                    {room.images?.map((image) => (
                      <div key={image.id} className="relative inline-block m-2">
                        <img
                          src={image.url}
                          alt={image.name}
                          className="h-20 w-20 object-cover rounded-lg cursor-pointer"
                          onClick={() => handleImageClick(image.url)} // Open modal on click
                        />
                        <button
                          onClick={() => handleDeleteImage(room.id, image.id)}
                          className="absolute -top-3 -right-3 bg-red-500 text-white rounded-full 
                                     flex items-center justify-center font-bold text-sm 
                                     transition-transform transform duration-300 ease-in-out 
                                     hover:scale-125 hover:bg-red-600 shadow-lg"
                          style={{ width: "30px", height: "30px" }}
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </td>
                  <td className="px-6 py-4 flex">
                    <div
                      className="font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline"
                      onClick={() => openEditModal(room.id)}
                    >
                      Edit
                    </div>
                    <div
                      className="font-medium cursor-pointer text-red-600 dark:text-red-500 hover:underline ms-3"
                      onClick={() => handleDelete(room.id)}
                    >
                      Remove
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {/* Image Modal */}
      <ImageModal
        isOpen={isImageModalOpen}
        onClose={() => setIsImageModalOpen(false)}
        imageUrl={selectedImageUrl}
      />
    </div>
  );
};

export default RoomType;
