import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deleteRateCategory, deleteRatePrice, getRateByResortIdRateId, getRatePriceByResortId } from "../../../../../../../apis/property";
import EditRatePrice from "./editRatePrice";
import CreateRatePrice from "./createRatePrice";
const RatePrice = () => {
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [rate, setRate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedRateId, setSelectedRateId] = useState(null);
  const [ratePrices, setRatePrices] = useState({});

  const fetchRate = async (rateId) => {
    try {
      const response = await getRateByResortIdRateId(rateId);
      console.log(response)
      return response.data.rates.name;
    } catch (error) {
      console.log('Error fetching rate ');
      return '';
    }
  };

  const fetchRatePrice = async () => {
    try {
      const response = await getRatePriceByResortId(id);
      const ratePrice = response.data.rates;
      // Fetch room class names
      const ratePromises = ratePrice.map(async (room) => {
        const rate = await fetchRate(room.room_rate_id);
        return { id: room.id, rate };
      });

      const rateResults = await Promise.all(ratePromises);
      const rateMap = rateResults.reduce((acc, { id, rate }) => {
        acc[id] = rate;
        return acc;
      }, {});

      setRate(rateMap);
      setRatePrices(ratePrice);
      setLoading(false);
    } catch (error) {
      console.log('Error fetching room category');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRatePrice();
  }, []);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = async () => {
    setIsModalOpen(false);
    await fetchRatePrice();
  };


  const openEditModal = (rateId) => {
    setSelectedRateId(rateId);
    setIsEditModalOpen(true);
  };

  const closeEditModal = async () => {
    setIsEditModalOpen(false);
    await fetchRatePrice();
    setSelectedRateId(null);
  };

  const handleDelete = async (rate_price_id) => {
    try {
      await deleteRatePrice(rate_price_id);
      toast.success("Rate price deleted successfully");
      await fetchRatePrice();
    } catch (error) {
      toast.error("Failed to delete rate price");
    }
  };
  return (
    <div>
      <div>
        <div className="mt-5 flex justify-between">
          <h1 className="text-2xl font-bold mt-3">
            Room Category Configuration
          </h1>
          <button
            onClick={openModal}
            type="button"
            className="text-white mt-3 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 ..."
          >
            Add Rate Price
          </button>
        </div>
        <CreateRatePrice
          isOpen={isModalOpen}
          onClose={closeModal}
        // onResortCreated={fetchResorts}
        />
        <EditRatePrice
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          selectedRateId={selectedRateId}
        />
        <div className="mt-10">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 shadow-lg">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Base Price
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Single Occupancy
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Double Occupancy
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Extra Adult
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Start Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    End Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Room Rate Type
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {ratePrices?.map((rateP) => (
                  <tr key={rateP.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {rateP.base_price}
                    </th>
                    <td className="px-6 py-4">{rateP.single_occupancy}</td>
                    <td className="px-6 py-4">{rateP.double_occupancy}</td>
                    <td className="px-6 py-4">{rateP.extra_adult}</td>
                    <td className="px-6 py-4">{rateP.start_date}</td>
                    <td className="px-6 py-4">{rateP.end_date}</td>
                    <td className="px-6 py-4">{rate[rateP.id]}</td>
                    <td className="px-6 py-4 flex">
                      <div
                        className="font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline" onClick={() => openEditModal(rateP.id)}
                      >
                        Edit
                      </div>
                      <div
                        className="font-medium cursor-pointer text-red-600 dark:text-red-500 hover:underline ms-3" onClick={() => handleDelete(rateP.id)}
                      >
                        Remove
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default RatePrice;
