import React, { useState, useEffect } from "react";
import CreateRateClassModal from "./createRateClass";
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deleteRateClass, getRateClassByResortId } from "../../../../../../../apis/property";
import EditRateClass from "./editRateClass";

const RateClass = () => {
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [rateClass, setRateClass] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedRateId, setSelectedRateId] = useState(null);


  const fetchRateClass = async () => {
    try {
      const response = await getRateClassByResortId(id);
      setRateClass(response.data.rate_classes);
      setLoading(false);
    } catch (error) {
      console.log('Error fetching rate class');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRateClass();
  }, []);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = async () => {
    setIsModalOpen(false);
    await fetchRateClass();
    // window.location.reload();
  };


  const openEditModal = (roomId) => {
    setSelectedRateId(roomId);
    setIsEditModalOpen(true);
  };

  const closeEditModal = async () => {
    setIsEditModalOpen(false);
    await fetchRateClass();
    setSelectedRateId(null);
  };
  
  const handleDelete = async (rate_class_id) => {
    try {
      await deleteRateClass(rate_class_id);
      toast.success("Rate Class deleted successfully");
      await fetchRateClass();
      // Optionally, fetch updated Rate classes or refresh the page
    } catch (error) {
      toast.error("Failed to delete rate Class");
    }
  };

  return (
    <div>
      <div>
        <div className="mt-5 flex justify-between">
          <h1 className="text-2xl font-bold mt-3">Rate Class Configuration</h1>
          <button
            onClick={openModal}
            type="button"
            className="text-white mt-3 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 ..."
          >
            Add Rate Class
          </button>
        </div>
        <CreateRateClassModal
          isOpen={isModalOpen}
          onClose={closeModal}
        />
         <EditRateClass
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          selectedRateId={selectedRateId}
        />
        <div className="mt-10">
        {loading ? (
            <p>Loading...</p>
          ) : (
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 shadow-lg">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Rate class Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Rate class Code
                </th>
                <th scope="col" className="px-6 py-3">
                  Rate class Description
                </th>
                <th scope="col" className="px-6 py-3">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
            {rateClass?.map((rate) => (
                  <tr key={rate.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {rate.name}
                    </th>
                    <td className="px-6 py-4">{rate.code}</td>
                    <td className="px-6 py-4">{rate.description}</td>
                <td className="px-6 py-4 flex">
                  <div
                    className="font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline"  onClick={() => openEditModal(rate.id)}
                  >
                    Edit
                  </div>
                  <div
                    className="font-medium cursor-pointer text-red-600 dark:text-red-500 hover:underline ms-3" onClick={() => handleDelete(rate.id)}
                  >
                    Remove
                  </div>
                </td>
              </tr>
                ))}
            </tbody>
          </table>
           )}
        </div>
      </div>
    </div>
  );
};

export default RateClass;
