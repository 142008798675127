import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

// Mock data for room availability
const roomAvailability = [
  { title: "5 DLX", start: new Date(2023, 4, 1), end: new Date(2023, 4, 1) },
  { title: "3 EXEC", start: new Date(2023, 4, 1), end: new Date(2023, 4, 1) },
  { title: "2 DLS", start: new Date(2023, 4, 2), end: new Date(2023, 4, 2) },
  { title: "1 PRE", start: new Date(2023, 4, 2), end: new Date(2023, 4, 2) },
  { title: "4 DLX", start: new Date(2023, 4, 3), end: new Date(2023, 4, 3) },
  { title: "2 EXEC", start: new Date(2023, 4, 3), end: new Date(2023, 4, 3) },
  { title: "5 DLS", start: new Date(2023, 4, 4), end: new Date(2023, 4, 4) },
  { title: "3 PRE", start: new Date(2023, 4, 4), end: new Date(2023, 4, 4) },
  // more data...
];

const localizer = momentLocalizer(moment);

const eventStyleGetter = (event) => {
  let backgroundColor = "";
  switch (event.type) {
    case "DLX":
      backgroundColor = "#ffadad";
      break;
    case "EXEC":
      backgroundColor = "#ffd6a5";
      break;
    case "DLS":
      backgroundColor = "#fdffb6";
      break;
    case "PRE":
      backgroundColor = "#caffbf";
      break;
    default:
      backgroundColor = "#ffffff";
  }
  return {
    style: { backgroundColor },
  };
};

function RoomInventoryCalendar() {
  return (
    <div style={{ height: "600px"}}>
      <Calendar
        localizer={localizer}
        events={roomAvailability}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 600 }}
        eventPropGetter={eventStyleGetter}
      />
    </div>
  );
}

export default RoomInventoryCalendar;
