
import React, { useState } from "react";
import "./dashboardLayout.css";
import { Link, Routes, Route, useLocation, useParams } from "react-router-dom";
import DashboardHome from "../pages/dashboard/dashboardHome";
import Users from "../pages/dashboard/users/users";
import Property from "../pages/dashboard/property/property";
import ViewProperty from "../pages/dashboard/property/viewProperty/viewProperty";
import {
  MenuIcon,
  HomeIcon,
  CalendarIcon,
  UserGroupIcon,
  LogoutIcon,
} from "@heroicons/react/outline";

const DashboardLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const pageTitle = location.pathname.split("/").pop().toUpperCase();

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div
        className={`bg-gray-800 w-64 md:w-80 min-h-screen overflow-y-auto transition-all duration-300 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-64 md:-translate-x-0"
        } md:shadow-lg z-30`}
      >
        <div className="p-4 flex justify-between items-center md:justify-center md:items-start">
          <Link to="/" className="text-white text-lg font-semibold">
            <span className="text-yellow-500">Channel</span> Manager
          </Link>
          <button
            className="text-white md:hidden focus:outline-none focus:bg-gray-700 p-2 rounded-lg"
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            <MenuIcon className="h-6 w-6" />
          </button>
        </div>
        <nav className="mt-6 px-5">
          <Link
            to="/dashboard"
            className="sidebar active block py-3 px-4 text-gray-200 hover:bg-gray-700 hover:rounded-lg  hover:text-white transition duration-300 flex items-center"
          >
            <HomeIcon className="h-6 w-6 mr-2" />
            Dashboard
          </Link>
          <Link
            to="/dashboard/property"
            className="sidebar block py-3 px-4 mt-2 text-gray-200 hover:bg-gray-700 hover:rounded-lg hover:text-white transition duration-300 flex items-center"
          >
            <CalendarIcon className="h-6 w-6 mr-2" />
            Property
          </Link>
          <Link
            to="/dashboard/users"
            className="sidebar block py-3 px-4 mt-2 text-gray-200 hover:bg-gray-700 hover:rounded-lg hover:text-white transition duration-300 flex items-center"
          >
            <UserGroupIcon className="h-6 w-6 mr-2" />
            Users
          </Link>
          <Link
            to="/"
            className="sidebar block mt-40 px-4 py-3 text-gray-200 hover:bg-gray-700 hover:rounded-lg hover:text-white transition duration-300 flex items-center"
          >
            <LogoutIcon className="h-6 w-6 mr-2" />
            Logout
          </Link>
        </nav>
      </div>

      {/* Main content */}
      <div className="flex-1">
        {/* Topbar */}
        <div className="bg-white py-5 px-7 flex justify-between items-center shadow-md z-20">
          <h1 className="text-dark text-xl font-bold">{pageTitle}</h1>
          {/* Add any topbar elements here */}
        </div>

        {/* Main content area */}
        <div className="p-4">
          <Routes>
            <Route path="/" element={<DashboardHome />} />
            <Route path="/property" element={<Property />} />
            <Route path="/users" element={<Users />} />
            <Route path="/view-property/:id" element={<ViewProperty/>} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
