import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getRoomCategoryByResortId, uploadRoomTypeImage } from "../../../../../../../apis/property";
import { useParams } from "react-router-dom";

const CreateRoomType = ({ isOpen, onClose }) => {
  const { id } = useParams(); // Resort ID
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [formData, setFormData] = useState({
    name: "",
    code: "",
    description: "",
    total_count: "",
    min_occupancy: "",
    max_occupancy: "",
    room_category_id: "",
    resort_id: id,
  });
  const [roomCategory, setRoomCategory] = useState(null);
  const [image, setImage] = useState(null); // Image state for file upload

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]); // Store selected image
  };

  const fetchRoomCategory = async () => {
    try {
      const response = await getRoomCategoryByResortId(id);
      setRoomCategory(response.data);
    } catch (error) {
      console.log("Error fetching room categories:", error);
    }
  };

  useEffect(() => {
    fetchRoomCategory();
  }, []);

  const validateForm = () => {
    const { name, code, total_count, min_occupancy, max_occupancy, room_category_id } = formData;
    if (name.length < 3 || name.length > 50) {
      toast.error("Room type name must be between 3 and 50 characters.");
      return false;
    }
    if (code.length < 2 || code.length > 10) {
      toast.error("Room type code must be between 2 and 10 characters.");
      return false;
    }
    if (!/^\d+$/.test(total_count) || parseInt(total_count) <= 0) {
      toast.error("Total number must be a positive integer.");
      return false;
    }
    if (!/^\d+$/.test(min_occupancy) || parseInt(min_occupancy) < 1) {
      toast.error("Minimum occupancy must be at least 1.");
      return false;
    }
    if (!/^\d+$/.test(max_occupancy) || parseInt(max_occupancy) < parseInt(min_occupancy)) {
      toast.error("Maximum occupancy must be greater than or equal to minimum occupancy.");
      return false;
    }
    if (!room_category_id) {
      toast.error("Please select a room category.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const accessToken = localStorage.getItem("accessToken");

      // Create room type
      const response = await fetch(`${baseUrl}/resort/room/type`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      const roomTypeData = await response.json();

      if (response.ok) {
        toast.success("Room Type created successfully");

        // If an image is selected, upload it
        if (image) {
          const formDataImage = new FormData();
          formDataImage.append("image", image);
          formDataImage.append("room_type_id", roomTypeData.data.id); // Use room type ID from response

          // Upload the image
          await uploadRoomTypeImage(id, formDataImage);
          toast.success("Image uploaded successfully");
        }

        onClose();
      } else {
        toast.error("Failed to create Room Type");
        console.error("Failed to create Room Type:", roomTypeData);
      }
    } catch (error) {
      toast.error("Error occurred while creating Room Type");
      console.error("Error occurred while creating Room Type:", error);
    }
  };

  return (
    <div>
      <div className={`fixed z-10 inset-0 overflow-y-auto ${isOpen ? "block" : "hidden"}`}>
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
          &#8203;
          <div
            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <form onSubmit={handleSubmit}>
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="">
                  <div className="mt-3">
                    <h3 className="text-lg leading-6 font-bold text-gray-900" id="modal-headline">
                      Add Room Type
                    </h3>
                    <div className="mt-10">
                      {/* Room Type Name */}
                      <div className="mb-4">
                        <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">
                          Room Type Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="Enter Room Type Name"
                          required
                        />
                      </div>

                      {/* Room Type Code */}
                      <div className="mb-4">
                        <label htmlFor="code" className="block text-gray-700 text-sm font-bold mb-2">
                          Room Type Code
                        </label>
                        <input
                          type="text"
                          id="code"
                          name="code"
                          value={formData.code}
                          onChange={handleChange}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="Enter Room Type code"
                          required
                        />
                      </div>

                      <div className="mb-4">
                        <label htmlFor="total_count" className="block text-gray-700 text-sm font-bold mb-2">
                          Total Number
                        </label>
                        <textarea
                          id="total_count"
                          name="total_count"
                          value={formData.total_count}
                          onChange={handleChange}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="Enter Total Number"
                          required
                        />
                      </div>

                      <div className="mb-4">
                        <label htmlFor="min_occupancy" className="block text-gray-700 text-sm font-bold mb-2">
                          Minimum Occupancy
                        </label>
                        <textarea
                          id="min_occupancy"
                          name="min_occupancy"
                          value={formData.min_occupancy}
                          onChange={handleChange}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="Enter Minimum Occupancy"
                          required
                        />
                      </div>

                      <div className="mb-4">
                        <label htmlFor="min_occupancy" className="block text-gray-700 text-sm font-bold mb-2">
                          Maximum Occupancy
                        </label>
                        <textarea
                          id="max_occupancy"
                          name="max_occupancy"
                          value={formData.max_occupancy}
                          onChange={handleChange}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="Enter Maximum Occupancy"
                          required
                        />
                      </div>

                      {/* Room Type Description */}
                      <div className="mb-4">
                        <label htmlFor="description" className="block text-gray-700 text-sm font-bold mb-2">
                          Room Type Description
                        </label>
                        <textarea
                          id="description"
                          name="description"
                          value={formData.description}
                          onChange={handleChange}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="Enter Room Type description"
                          required
                        />
                      </div>

                      {/* Image Upload */}
                      <div className="mb-4">
                        <label htmlFor="image" className="block text-gray-700 text-sm font-bold mb-2">
                          Upload Room Image
                        </label>
                        <input
                          type="file"
                          id="image"
                          name="image"
                          onChange={handleImageChange}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                      </div>

                      {/* Room Category */}
                      <div className="mb-4">
                        <label htmlFor="room_category_id" className="block text-gray-700 text-sm font-bold mb-2">
                          Room Category
                        </label>
                        <select
                          id="class"
                          name="room_category_id"
                          value={formData.room_category_id}
                          onChange={handleChange}
                          className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                          required
                        >
                          <option value="">Select Room Category</option>
                          {roomCategory?.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Add Room Type
                </button>
                <button
                  onClick={onClose}
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateRoomType;

// import React, { useEffect, useState } from "react";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import {
//   getRoomCategoryByResortId,
//   uploadRoomTypeImage,
//   deleteRoomTypeImage,
// } from "../../../../../../../apis/property";
// import { useParams } from "react-router-dom";
// import { useDropzone } from "react-dropzone";

// const CreateRoomType = ({ isOpen, onClose }) => {
//   const { id } = useParams(); // Resort ID
//   const baseUrl = process.env.REACT_APP_BASE_URL;
//   const [formData, setFormData] = useState({
//     name: "",
//     code: "",
//     description: "",
//     total_count: "",
//     min_occupancy: "",
//     max_occupancy: "",
//     room_category_id: "",
//     resort_id: id,
//   });
//   const [roomCategory, setRoomCategory] = useState(null);
//   const [uploadedImages, setUploadedImages] = useState([]); // Track uploaded images
//   const [roomTypeId, setRoomTypeId] = useState(null); // Store the room type ID after creation

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const fetchRoomCategory = async () => {
//     try {
//       const response = await getRoomCategoryByResortId(id);
//       setRoomCategory(response.data);
//     } catch (error) {
//       console.log("Error fetching room categories:", error);
//     }
//   };

//   useEffect(() => {
//     fetchRoomCategory();
//   }, []);

//   // Modified handleSubmit to create room type first, then upload images
//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const accessToken = localStorage.getItem("accessToken");

//       // Create room type
//       const response = await fetch(`${baseUrl}/resort/room/type`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${accessToken}`,
//         },
//         body: JSON.stringify(formData),
//       });

//       const roomTypeData = await response.json();

//       if (response.ok) {
//         toast.success("Room Type created successfully");
//         setRoomTypeId(roomTypeData.id); // Save the room type ID
//         uploadAllImages(roomTypeData.id); // Upload images with the room type ID
//       } else {
//         toast.error("Failed to create Room Type");
//         console.error("Failed to create Room Type:", roomTypeData);
//       }
//     } catch (error) {
//       toast.error("Error occurred while creating Room Type");
//       console.error("Error occurred while creating Room Type:", error);
//     }
//   };

//   // Handle image drop
//   const onDrop = (acceptedFiles) => {
//     setUploadedImages([...uploadedImages, ...acceptedFiles]);
//   };

//   // Upload all images after room type creation
//   const uploadAllImages = async (roomTypeId) => {
//     const accessToken = localStorage.getItem("accessToken");

//     for (let file of uploadedImages) {
//       const formDataImage = new FormData();
//       formDataImage.append("image", file);
//       formDataImage.append("room_type_id", Number(roomTypeId));

//       try {
//         // Upload the image
//         const response = await uploadRoomTypeImage(id, formDataImage, {
//           headers: {
//             Authorization: `Bearer ${accessToken}`,
//           },
//         });

//         const data = response.data;
//         toast.success(`${file.name} uploaded successfully`);
//       } catch (error) {
//         console.error("Image upload failed:", error);
//         toast.error("Image upload failed");
//       }
//     }
//   };

//   // Handle image deletion
//   const handleDeleteImage = async (imageId) => {
//     const accessToken = localStorage.getItem("accessToken");

//     try {
//       await deleteRoomTypeImage(id, imageId, {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//         },
//       });
//       setUploadedImages(uploadedImages.filter((img) => img.id !== imageId));
//       toast.success("Image deleted successfully");
//     } catch (error) {
//       console.error("Error deleting image:", error);
//       toast.error("Failed to delete image");
//     }
//   };

//   // Drag-and-drop configuration
//   const { getRootProps, getInputProps } = useDropzone({
//     onDrop,
//     accept: "image/*",
//     multiple: true,
//   });

//   return (
//     <div>
//       <div
//         className={`fixed z-10 inset-0 overflow-y-auto ${
//           isOpen ? "block" : "hidden"
//         }`}
//       >
//         <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
//           <div className="fixed inset-0 transition-opacity">
//             <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
//           </div>
//           <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
//           &#8203;
//           <div
//             className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
//             role="dialog"
//             aria-modal="true"
//             aria-labelledby="modal-headline"
//           >
//             <form onSubmit={handleSubmit}>
//               <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
//                 <div className="">
//                   <div className="mt-3">
//                     <h3
//                       className="text-lg leading-6 font-bold text-gray-900"
//                       id="modal-headline"
//                     >
//                       Add Room Type
//                     </h3>
//                     <div className="mt-10">
//                       {/* Room Type Fields */}

//                       {/* Room Type Name */}
//                       <div className="mb-4">
//                         <label
//                           htmlFor="name"
//                           className="block text-gray-700 text-sm font-bold mb-2"
//                         >
//                           Room Type Name
//                         </label>
//                         <input
//                           type="text"
//                           id="name"
//                           name="name"
//                           value={formData.name}
//                           onChange={handleChange}
//                           className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                           placeholder="Enter Room Type Name"
//                           required
//                         />
//                       </div>

//                       {/* Room Type Code */}
//                       <div className="mb-4">
//                         <label
//                           htmlFor="code"
//                           className="block text-gray-700 text-sm font-bold mb-2"
//                         >
//                           Room Type Code
//                         </label>
//                         <input
//                           type="text"
//                           id="code"
//                           name="code"
//                           value={formData.code}
//                           onChange={handleChange}
//                           className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                           placeholder="Enter Room Type code"
//                           required
//                         />
//                       </div>

//                       <div className="mb-4">
//                         <label
//                           htmlFor="total_count"
//                           className="block text-gray-700 text-sm font-bold mb-2"
//                         >
//                           Total Number
//                         </label>
//                         <textarea
//                           id="total_count"
//                           name="total_count"
//                           value={formData.total_count}
//                           onChange={handleChange}
//                           className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                           placeholder="Enter Total Number"
//                           required
//                         />
//                       </div>
//                       <div className="mb-4">
//                         <label
//                           htmlFor="min_occupancy"
//                           className="block text-gray-700 text-sm font-bold mb-2"
//                         >
//                           Minimum Occupancy
//                         </label>
//                         <textarea
//                           id="min_occupancy"
//                           name="min_occupancy"
//                           value={formData.min_occupancy}
//                           onChange={handleChange}
//                           className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                           placeholder="Enter Minimum Occupancy"
//                           required
//                         />
//                       </div>
//                       <div className="mb-4">
//                         <label
//                           htmlFor="min_occupancy"
//                           className="block text-gray-700 text-sm font-bold mb-2"
//                         >
//                           Maximum Occupancy
//                         </label>
//                         <textarea
//                           id="max_occupancy"
//                           name="max_occupancy"
//                           value={formData.max_occupancy}
//                           onChange={handleChange}
//                           className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                           placeholder="Enter Maximum Occupancy"
//                           required
//                         />
//                       </div>

//                       {/* Room Type Description */}
//                       <div className="mb-4">
//                         <label
//                           htmlFor="description"
//                           className="block text-gray-700 text-sm font-bold mb-2"
//                         >
//                           Room Type Description
//                         </label>
//                         <textarea
//                           id="description"
//                           name="description"
//                           value={formData.description}
//                           onChange={handleChange}
//                           className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                           placeholder="Enter Room Type description"
//                           required
//                         />
//                       </div>

//                       {/* Room Category */}
//                       <div className="mb-4">
//                         <label
//                           htmlFor="room_category_id"
//                           className="block text-gray-700 text-sm font-bold mb-2"
//                         >
//                           Room Category
//                         </label>
//                         <select
//                           id="class"
//                           name="room_category_id"
//                           value={formData.room_category_id}
//                           onChange={handleChange}
//                           className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
//                           required
//                         >
//                           {roomCategory?.map((item) => (
//                             <option key={item.id} value={item.id}>
//                               {item.name}
//                             </option>
//                           ))}
//                         </select>
//                       </div>

//                       {/* Drag and Drop Image Upload */}
//                       <div className="mb-4">
//                         <label className="block text-gray-700 text-sm font-bold mb-2">
//                           Upload Room Images
//                         </label>
//                         <div
//                           {...getRootProps()}
//                           className="border-dashed border-2 border-gray-300 rounded-md p-4 text-center cursor-pointer"
//                         >
//                           <input {...getInputProps()} />
//                           <p>
//                             Drag & drop images here, or click to select files
//                           </p>
//                         </div>
//                       </div>

//                       {/* Display uploaded images with delete option */}
//                       {uploadedImages.length > 0 && (
//                         <div className="mt-4">
//                           {uploadedImages.map((image) => (
//                             <div
//                               key={image.id}
//                               className="relative inline-block m-2"
//                             >
//                               <img
//                                 src={URL.createObjectURL(image)} // Temporarily display selected images
//                                 alt="Uploaded"
//                                 className="w-24 h-24 object-cover rounded-md"
//                               />
//                               <button
//                                 onClick={() => handleDeleteImage(image.id)}
//                                 className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
//                               >
//                                 X
//                               </button>
//                             </div>
//                           ))}
//                         </div>
//                       )}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
//                 <button
//                   type="submit"
//                   className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
//                 >
//                   Add Room Type
//                 </button>
//                 <button
//                   onClick={onClose}
//                   type="button"
//                   className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
//                 >
//                   Cancel
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CreateRoomType;
