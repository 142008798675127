import React from "react";

const Card = ({ title, onClick, icon: Icon, text }) => {
  return (
    <div
      className="cursor-pointer border border-gray-200 rounded-lg p-5 py-10 shadow-lg hover:shadow transition-shadow duration-200"
      onClick={onClick}
    >
      <div className="flex items-center">
        <div>
        {Icon && <Icon className="h-10 w-10 text-customBlue mr-2" />}
        </div>
        <div className="ms-5">
          <h2 className="text-lg font-bold mb-2">{title}</h2>
          <p className="text-gray-500">{text}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
