import React, { useState } from "react";
import Card from "../../../../../../components/Card";
import RoomTypeCard from "./roomType/roomType";
import RoomCategoryCard from "./roomCategory/roomCategory";
import RoomClassCard from "./roomClass/roomClass";
import {
  HomeIcon,
  ViewBoardsIcon,
  SparklesIcon,
  ArrowNarrowLeftIcon,
} from "@heroicons/react/outline";
import RateClassCard from "./rateClass/rateClass";
import RateCategoryCard from "./rateCategory/rateCategory";
import RateCard from "./rate/rate";
import RatePriceCard from "./ratePrice/ratePrice";
import UsersCard from "./propertyUser/users";
import AmenityCategoryCard from "./amenityCategory/amenityCategory";

const Configurations = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const renderCardContent = () => {
    switch (selectedCard) {
      case "Card One":
        return <RoomTypeCard />;
      case "Card Two":
        return <RoomCategoryCard />;
      case "Card Three":
        return <RoomClassCard />;
      case "Card Four":
        return <RateClassCard />;
      case "Card Five":
        return <RateCategoryCard />;
      case "Card Six":
        return <RateCard />;
      case "Card Seven":
        return <RatePriceCard />;
      case "Card Eight":
        return <UsersCard />;
      case "Card Nine":
        return <AmenityCategoryCard />;
      default:
        return null;
    }
  };

  return (
    <div>
      {selectedCard ? (
        <div>
          <div
            className="text-dark mt-3 font-bold text-sm px-5 py-3 text-center inline-flex rounded items-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-100 hover:bg-gray-300 duration-100 cursor-pointer"
            onClick={() => setSelectedCard(null)}
          >
            <ArrowNarrowLeftIcon className="h-5 w-5 text-dark mr-2" />
            Back to Configurations
          </div>

          {renderCardContent()}
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          <Card
            title="Amenity Category Configuration"
            icon={SparklesIcon}
            text="All information of amenity set up"
            onClick={() => setSelectedCard("Card Nine")}
          />
          <Card
            title="Property User Configuration"
            icon={SparklesIcon}
            text="All information of property ser set up"
            onClick={() => setSelectedCard("Card Eight")}
          />
          <Card
            title="Rate Price Configuration"
            icon={SparklesIcon}
            text="All information of rate price set up"
            onClick={() => setSelectedCard("Card Seven")}
          />
          <Card
            title="Rate Configuration"
            icon={SparklesIcon}
            text="All information of rate set up"
            onClick={() => setSelectedCard("Card Six")}
          />
          <Card
            title="Rate Category Configuration"
            icon={SparklesIcon}
            text="All information of rate category set up"
            onClick={() => setSelectedCard("Card Five")}
          />
          <Card
            title="Rate Class Configuration"
            icon={SparklesIcon}
            text="All information of rate class set up"
            onClick={() => setSelectedCard("Card Four")}
          />
          <Card
            title="Room Class Configuration"
            icon={SparklesIcon}
            text="All information of room class set up"
            onClick={() => setSelectedCard("Card Three")}
          />
          <Card
            title="Room Category Configuration"
            icon={ViewBoardsIcon}
            text="All information of room category set up"
            onClick={() => setSelectedCard("Card Two")}
          />
          <Card
            title="Room Type Configuration"
            icon={HomeIcon}
            text="All information of room type set up"
            onClick={() => setSelectedCard("Card One")}
          />
        </div>
      )}
    </div>
  );
};

export default Configurations;
