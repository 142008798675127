import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const dashboardHome = () => {
  const data = [
    { name: "January", uv: 4000, pv: 2400, amt: 2400 },
    { name: "February", uv: 3000, pv: 1398, amt: 2210 },
    { name: "March", uv: 2000, pv: 9800, amt: 2290 },
    { name: "April", uv: 2780, pv: 3908, amt: 2000 },
    { name: "May", uv: 1890, pv: 4800, amt: 2181 },
    { name: "June", uv: 2390, pv: 3800, amt: 2500 },
    { name: "July", uv: 3490, pv: 4300, amt: 2100 },
  ];

  return (
    <div>
      {" "}
      <div className="container mx-auto px-4 py-8">
        {/* First Row */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
          {/* Total Users */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex items-center justify-between mb-4">
              {/* Icon */}
              <svg
                className="w-8 h-8 text-blue-600 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
              {/* Total Users */}
              <span className="text-lg font-semibold">Total Users: 4</span>
            </div>

          </div>

          {/* Total Resorts */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex items-center justify-between mb-4">
              {/* Icon */}
              <svg
                className="w-8 h-8 text-green-600 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14"
                />
              </svg>
              {/* Total Resorts */}
              <span className="text-lg font-semibold">Total Resorts: 5</span>
            </div>
     
          </div>

          {/* All Availabilities */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex items-center justify-between mb-4">
              {/* Icon */}
              <svg
                className="w-8 h-8 text-yellow-600 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
              {/* All Availabilities */}
              <span className="text-lg font-semibold">
                All Availabilities: 20
              </span>
            </div>
    
          </div>

          {/* All Reservations */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex items-center justify-between mb-4">
              {/* Icon */}
              <svg
                className="w-8 h-8 text-red-600 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 13l-3 3m0 0l-3-3m3 3V8m0 0v8m5-10a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              {/* All Reservations */}
              <span className="text-lg font-semibold">
                All Reservations: 15
              </span>
            </div>
          </div>
        </div>

        {/* Second Row */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex justify-between mb-5">
              <div className="flex">
                <div>
                  <h1>
                    <span className="font-bold" style={{ color: "#6870fa" }}>
                      Total Revenue
                    </span>
                  </h1>
                  <small>12.04.2022 - 12.05.2022</small>
                </div>
                <div className="ms-5">
                  <h1>
                    <span className="font-bold">Total Sales</span>
                  </h1>
                  <small>12.04.2022 - 12.05.2022</small>
                </div>
              </div>
              <div>
                <div className="bg-[#f1f1f1] rounded-lg p-2">
                  <ul class="flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                    <li class="me-2">
                      <a
                        href="/"
                        class="inline-block px-4 py-1 text-dark bg-white rounded-lg active"
                        aria-current="page"
                      >
                        Day
                      </a>
                    </li>
                    <li class="me-2">
                      <a
                        href="/"
                        class="inline-block px-4 py-1 rounded-lg hover:text-gray-900 hover:bg-white"
                      >
                        Week
                      </a>
                    </li>
                    <li class="me-2">
                      <a
                        href="/"
                        class="inline-block px-4 py-1 rounded-lg hover:text-gray-900 hover:bg-white"
                      >
                        Month
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <LineChart width={700} height={500} data={data}>
              <XAxis dataKey="name" />
              <YAxis />
              <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="pv" stroke="#8884d8" />
              <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
            </LineChart>
          </div>

          {/* Bar Chart */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="pv" stackId="a" fill="#8884d8" />
                <Bar dataKey="uv" stackId="a" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default dashboardHome;
