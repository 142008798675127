import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;

// Function to delete room type image
export const deleteRoomTypeImage = async (resortId, imageId) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.delete(
      `${baseUrl}/room-type/image/store/${resortId}/${imageId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting room type image:", error);
    throw error;
  }
};



export const uploadRoomTypeImage = async (resortId, imageData) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.post(`${baseUrl}/room-type/image/store/${resortId}`, imageData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error uploading room type image:', error);
    throw error;
  }
};




export const getAllAmenityCategories = async () => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.get(`${baseUrl}/resort/amenity/category`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching amenity category:', error);
    throw error;
  }
};

export const deleteAmenityCategory = async (amenity_category_id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.delete(`${baseUrl}/resort/amenity/category/${amenity_category_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting amenity category:', error);
    throw error;
  }
};

export const getAmenityCategoryById = async (amenity_category_id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.get(`${baseUrl}/resort/amenity/category/${amenity_category_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching Amenity Category:', error);
    throw error;
  }
};

export const getRoomClassByResortId = async (id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.get(`${baseUrl}/resort/room/class/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching room class:', error);
    throw error;
  }
};
export const getRoomClassByResortIdResorClasstId = async (id, resort_class_id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.get(`${baseUrl}/resort/room/class/${id}/${resort_class_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching room class:', error);
    throw error;
  }
};

export const deleteRoomClass = async (resort_class_id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.delete(`${baseUrl}/resort/room/class/${resort_class_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting room class:', error);
    throw error;
  }
};

export const getRoomCategoryByResortId = async (id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.get(`${baseUrl}/resort/room/category/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching room class:', error);
    throw error;
  }
};

export const getRoomClassByResortIdResorCategoryId = async (id, resort_class_id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.get(`${baseUrl}/resort/room/category/${id}/${resort_class_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching room class:', error);
    throw error;
  }
};
export const deleteRoomCategory = async (resort_category_id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.delete(`${baseUrl}/resort/room/category/${resort_category_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error) {
    console.error('Error deleting room class:', error);
    throw error;
  }
};

export const getRoomTypeByResortId = async (id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.get(`${baseUrl}/resort/room/type/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching room class:', error);
    throw error;
  }
};

export const getRoomTypeByResortIdResorTypeId = async (id, resort_type_id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.get(`${baseUrl}/resort/room/type/${id}/${resort_type_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching room class:', error);
    throw error;
  }
};

export const deleteRoomType = async (resort_type_id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.delete(`${baseUrl}/resort/room/type/${resort_type_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error) {
    console.error('Error deleting room class:', error);
    throw error;
  }
};




export const getRateClassByResortId = async (id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.get(`${baseUrl}/rate/${id}/class`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching room class:', error);
    throw error;
  }
};



export const getRateClassByResortIdRateClasstId = async (rate_class_id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.get(`${baseUrl}/rate/class/${rate_class_id}`,{
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching room class:', error);
    throw error;
  }
};

export const deleteRateClass = async (rate_class_id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.delete(`${baseUrl}/rate/class/${rate_class_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting room class:', error);
    throw error;
  }
};

export const getRateCategoryByResortId = async (id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.get(`${baseUrl}/rate/${id}/category`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching room class:', error);
    throw error;
  }
};

export const getRateCategoryByResortIdResorCategoryId = async (rate_categoory_id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.get(`${baseUrl}/rate/category/${rate_categoory_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching room class:', error);
    throw error;
  }
};

export const deleteRateCategory = async (rate_category_id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.delete(`${baseUrl}/rate/category/${rate_category_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting room class:', error);
    throw error;
  }
};


export const getRateByResortId = async (id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.get(`${baseUrl}/rate/${id}/resort`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching rate:', error);
    throw error;
  }
};

export const getRateByResortIdRateId = async (rate_id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.get(`${baseUrl}/rate/${rate_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching room class:', error);
    throw error;
  }
};


export const deleteRate = async (rate_id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.delete(`${baseUrl}/rate/${rate_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting rate:', error);
    throw error;
  }
};

export const getRatePriceByResortId = async (id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.get(`${baseUrl}/rate/${id}/prices/2022-01-01/2024-06-30`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching rate price:', error);
    throw error;
  }
};


export const getRatePriceByResortIdRatePriceId = async (rate_id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.get(`${baseUrl}/rate/price/${rate_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching room class:', error);
    throw error;
  }
};


export const deleteRatePrice = async (rate_id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.delete(`${baseUrl}/rate/price/${rate_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting rate:', error);
    throw error;
  }
};