import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateRoomCategoryModal from "./createRoomCategory";
import { deleteRoomCategory, getRoomCategoryByResortId, getRoomClassByResortIdResorClasstId } from "../../../../../../../apis/property";
import EditRoomCategory from "./editRoomCategory";
const RoomCategory = () => {
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [roomCategory, setRoomCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [roomClassNames, setRoomClassNames] = useState({});

  const fetchRoomClass = async (roomClassId) => {
    try {
      const response = await getRoomClassByResortIdResorClasstId(id, roomClassId);
      return response.data.name;
    } catch (error) {
      console.log('Error fetching room class');
      return '';
    }
  };

  const fetchRoomCategory = async () => {
    try {
      const response = await getRoomCategoryByResortId(id);
      const roomCategories = response.data;
       // Fetch room class names
       const roomClassNamesPromises = roomCategories.map(async (room) => {
        const roomClassName = await fetchRoomClass(room.room_class_id);
        return { id: room.id, roomClassName };
      });
      
      const roomClassNamesResults = await Promise.all(roomClassNamesPromises);
      const roomClassNamesMap = roomClassNamesResults.reduce((acc, { id, roomClassName }) => {
        acc[id] = roomClassName;
        return acc;
      }, {});
      
      setRoomClassNames(roomClassNamesMap);
      setRoomCategory(roomCategories);
      setLoading(false);
    } catch (error) {
      console.log('Error fetching room category');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRoomCategory();
  }, []);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = async () => {
    setIsModalOpen(false);
    await fetchRoomCategory();
  };


  const openEditModal = (roomId) => {
    setSelectedRoomId(roomId);
    setIsEditModalOpen(true);
  };

  const closeEditModal = async () => {
    setIsEditModalOpen(false);
    await fetchRoomCategory();
    setSelectedRoomId(null);
  };

  const handleDelete = async (resort_category_id) => {
    try {
      await deleteRoomCategory(resort_category_id);
      toast.success("Room Category deleted successfully");
      await fetchRoomCategory();
    } catch (error) {
      toast.error("Failed to delete Room Category");
    }
  };
  return (
    <div>
      <div>
        <div className="mt-5 flex justify-between">
          <h1 className="text-2xl font-bold mt-3">
            Room Category Configuration
          </h1>
          <button
            onClick={openModal}
            type="button"
            className="text-white mt-3 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 ..."
          >
            Add Room Category
          </button>
        </div>
        <CreateRoomCategoryModal
          isOpen={isModalOpen}
          onClose={closeModal}
          // onResortCreated={fetchResorts}
        />
        <EditRoomCategory
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          selectedRoomId={selectedRoomId}
        />
        <div className="mt-10">
        {loading ? (
            <p>Loading...</p>
          ) : (
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 shadow-lg">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Room Category Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Room Category Code
                </th>
                <th scope="col" className="px-6 py-3">
                  Room Category Description
                </th>
                <th scope="col" className="px-6 py-3">
                  Room class
                </th>
                <th scope="col" className="px-6 py-3">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
            {roomCategory?.map((room) => (
            <tr key={room.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {room.name}
                </th>
                <td className="px-6 py-4">{room.code}</td>
                <td className="px-6 py-4">{room.description}</td>
                <td className="px-6 py-4">{roomClassNames[room.id]}</td>
                <td className="px-6 py-4 flex">
                <div
                    className="font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline"  onClick={() => openEditModal(room.id)}
                  >
                    Edit
                  </div>
                  <div
                    className="font-medium cursor-pointer text-red-600 dark:text-red-500 hover:underline ms-3" onClick={() => handleDelete(room.id)}
                  >
                    Remove
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
             )}
        </div>
      </div>
    </div>
  );
};

export default RoomCategory;
