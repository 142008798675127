import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { getRoomClassByResortId } from "../../../../../../../apis/property";

const CreateRoomCategory = ({ isOpen, onClose, fetchResorts }) => {
  const [roomClass, setRoomClass] = useState(null);
  const { id } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [formData, setFormData] = useState({
    name: "",
    code: "",
    description: "",
    room_class_id: "",
    resort_id: id,
  });
  const fetchRoomClass = async () => {
    try {
      const response = await getRoomClassByResortId(id);
      setRoomClass(response.data);
    } catch (error) {
      console.log("Error fetching room class");
    }
  };

  useEffect(() => {
    fetchRoomClass();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await fetch(`${baseUrl}/resort/room/category`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Room Category created successfully");
        onClose();
      } else {
        toast.error("Failed to create Room Category");
        console.error("Failed to create Room Category");
      }
    } catch (error) {
      toast.error("Error occurred while creating Room Category");
      console.error("Error occurred while creating Room Category:", error);
    }
  };

  return (
    <div>
      <div className={`fixed z-10 inset-0 overflow-y-auto ${isOpen ? "block" : "hidden"}`}>
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
          &#8203;
          <div
            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <form onSubmit={handleSubmit}>
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="">
                  <div className="mt-3">
                    <h3 className="text-lg leading-6 font-bold text-gray-900" id="modal-headline">
                      Add Room Category
                    </h3>
                    <div className="mt-10">
                      <div className="mb-4">
                        <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">
                          Room Category Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="Enter Room Category Name"
                          required
                        />
                      </div>

                      {/* Add other input fields for collecting resort data */}

                      {/* For Resort Code */}
                      <div className="mb-4">
                        <label htmlFor="code" className="block text-gray-700 text-sm font-bold mb-2">
                          Room Category Code
                        </label>
                        <input
                          type="text"
                          id="code"
                          name="code"
                          value={formData.code}
                          onChange={handleChange}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="Enter Room Category code"
                          required
                        />
                      </div>

                      {/* For Resort Description */}
                      <div className="mb-4">
                        <label htmlFor="description" className="block text-gray-700 text-sm font-bold mb-2">
                          Room Category Description
                        </label>
                        <textarea
                          id="description"
                          name="description"
                          value={formData.description}
                          onChange={handleChange}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="Enter Room Category description"
                          required
                        />
                      </div>

                      <div className="mb-4">
                        <label htmlFor="class" className="block text-gray-700 text-sm font-bold mb-2">
                          Room Class
                        </label>
                        <select
                          id="class"
                          name="room_class_id"
                          value={formData.room_class_id}
                          onChange={handleChange}
                          className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                          required
                        >
                          <option value="">Select Room Class</option>
                          {roomClass?.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* Add the remaining input fields similarly */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Add Room Category
                </button>
                <button
                  onClick={onClose}
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateRoomCategory;
